import type { Ref } from 'vue'

/**
 * @TODO
 * see https://github.com/nuxt/nuxt/issues/13471
 * see https://github.com/vuejs/core/pull/5952
 * see https://github.com/vuejs/core/issues/5844
 * Если будут закрыты эти issue то можно будет использовать onMounted
 *
 * @param element
 * @param listener
 * @param checkTimeLimit
 */
export default function useSafeOnMounted(
  element: Ref<HTMLElement>,
  listener: () => void,
  checkTimeLimit = 500
) {
  if (process.client) {
    const checkInterval = 100
    let checksLeft = checkTimeLimit / checkInterval

    const check = () => {
      if (element.value?.isConnected) {
        listener()
      } else if (checksLeft > 0) {
        setTimeout(check, checkInterval)
        checksLeft--
      }
    }

    onMounted(() => {
      check()
    })
  }
}
